<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: "App",
  title: 'Humanwise App',
  data() {
    return {
      checkTokenExpireTimer: null,
      tokenExpireMessages: {
        threeMinutes: {
          showed: false
        },
        twoMinutes: {
          showed: false
        },
        oneMinutes: {
          showed: false
        },
      },
    }
  },
  computed: {
    tokenExpireTimeStamp: function () {
      return this.$store.state.auth.expire_timestamp;
    },
  },
  methods: {
    setCheckTokenExpireTimer(delay = 3000) {
      if (this.checkTokenExpireTimer) {
        clearTimeout(this.checkTokenExpireTimer);
      }

      this.checkTokenExpireTimer = setTimeout(this.checkTokenExpire, delay);
    },
    calculateTokenExpireTimeInSeconds() {
      let now = new Date();
      let expireDate = new Date(this.tokenExpireTimeStamp);
      let diffInMs = expireDate - now;
      let diffInSec = Math.round(diffInMs / 1000);

      if (diffInSec < 0) {
        return 0;
      }

      return diffInSec;
    },
    checkTokenExpire() {
      let diffInSec = this.calculateTokenExpireTimeInSeconds();

      if (diffInSec > 0 && diffInSec < 300) {
        this.$store.dispatch("auth/refreshToken").then(() => {
          this.setCheckTokenExpireTimer();
        }).catch(() => {
          this.setCheckTokenExpireTimer(5000);
        });
      } else {
        this.setCheckTokenExpireTimer();
      }
    }
  },
  updated() {
    this.setCheckTokenExpireTimer();
  }
};
</script>

<style>
.v-main {
  background-color: #004572;
  color: white !important;
}

.theme--light.v-input {
  color: white !important;
}

.cursor-pointer {
  cursor: pointer;
}

.color-accent {
  color: #F08A00!important;
}

.background-darkblue {
  background-color: #004572;
}

.background-blue {
  background-color: #009EE0!important;
}

.background-orange {
  background-color: #F08A00;
}

.bg-gray {
  background-color: #f3f3f3;
}

.hover-bg-gray:hover {
  background-color: #f3f3f3;
}
</style>
